import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Talon = () => {
  return (
    <Layout>
      <Head title="Talon Abraxas" />
      <h3>Talon Abraxas</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        United Kingdom
      </h4>
      <p>
        <OutboundLink href="http://talonabraxas.blogspot.com/">
          Website
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.deviantart.com/talonabraxas">
          DeviantArt
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://talonabraxas.tumblr.com/">
          Tumblr
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.facebook.com/profile.php?id=100050477380184">
          Facebook
        </OutboundLink>
      </p>
      <p>
        To be a star, you must shine your own light, follow your own path, and
        don't worry about the darkness, for that is when the stars shine
        brightest!
      </p>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/07b7dd70-1310-4c37-b7df-4518dbf18363/dfantg6-26b07dd5-ce47-496c-b77d-9aa5f6fd369e.png/v1/fill/w_600,h_1201,q_80,strp/och_by_talonabraxas_dfantg6-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTIwMSIsInBhdGgiOiJcL2ZcLzA3YjdkZDcwLTEzMTAtNGMzNy1iN2RmLTQ1MThkYmYxODM2M1wvZGZhbnRnNi0yNmIwN2RkNS1jZTQ3LTQ5NmMtYjc3ZC05YWE1ZjZmZDM2OWUucG5nIiwid2lkdGgiOiI8PTYwMCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.Xz874CVIbHOT9Q1LL4IbgYrSziLocDQO0NWeVKLoWOY"
          alt="Talon Abraxas art"
        />
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/07b7dd70-1310-4c37-b7df-4518dbf18363/dfantci-4cffa7bd-3cde-48e4-89c5-4b7e2ab32298.png/v1/fill/w_600,h_1201,q_80,strp/dream_quest_of_unknown_kadath_by_talonabraxas_dfantci-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTIwMSIsInBhdGgiOiJcL2ZcLzA3YjdkZDcwLTEzMTAtNGMzNy1iN2RmLTQ1MThkYmYxODM2M1wvZGZhbnRjaS00Y2ZmYTdiZC0zY2RlLTQ4ZTQtODljNS00YjdlMmFiMzIyOTgucG5nIiwid2lkdGgiOiI8PTYwMCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.JkSVQXk-8bnphasip3tfIM49am4mgHMoXDzsYFCVhLk"
          alt="Talon Abraxas art"
        />
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/07b7dd70-1310-4c37-b7df-4518dbf18363/dfaidup-6ea93a6e-00f7-40cf-8fb1-3807495a8034.png/v1/fill/w_1024,h_1280,q_80,strp/graal_of_babalon_by_talonabraxas_dfaidup-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTI4MCIsInBhdGgiOiJcL2ZcLzA3YjdkZDcwLTEzMTAtNGMzNy1iN2RmLTQ1MThkYmYxODM2M1wvZGZhaWR1cC02ZWE5M2E2ZS0wMGY3LTQwY2YtOGZiMS0zODA3NDk1YTgwMzQucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.zF3gYF_CJ15xus-g0soHTGE2XTOooAoo5GgrvMzqUZg"
          alt="Talon Abraxas art"
        />
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/07b7dd70-1310-4c37-b7df-4518dbf18363/dfb6w3k-5bd5694a-69fd-47c7-bf3a-cc64429cb19d.png/v1/fill/w_600,h_1459,q_80,strp/quetzalcatl_by_talonabraxas_dfb6w3k-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTQ1OSIsInBhdGgiOiJcL2ZcLzA3YjdkZDcwLTEzMTAtNGMzNy1iN2RmLTQ1MThkYmYxODM2M1wvZGZiNnczay01YmQ1Njk0YS02OWZkLTQ3YzctYmYzYS1jYzY0NDI5Y2IxOWQucG5nIiwid2lkdGgiOiI8PTYwMCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.j4xRXZmGw49Pz0JZK9e60ASHws6svGsY7pIRnpEYAwE"
          alt="Talon Abraxas art"
        />
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/07b7dd70-1310-4c37-b7df-4518dbf18363/dfbfnzm-bd431f9f-5cae-41ed-a041-637c4641c05f.png/v1/fill/w_900,h_900,q_80,strp/sigil_of_samuel_by_talonabraxas_dfbfnzm-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9OTAwIiwicGF0aCI6IlwvZlwvMDdiN2RkNzAtMTMxMC00YzM3LWI3ZGYtNDUxOGRiZjE4MzYzXC9kZmJmbnptLWJkNDMxZjlmLTVjYWUtNDFlZC1hMDQxLTYzN2M0NjQxYzA1Zi5wbmciLCJ3aWR0aCI6Ijw9OTAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.c-Wls1kfAOweZ1U03VXOXTqQ0frKh0PPm6i_yvUHBC4"
          alt="Talon Abraxas art"
        />
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/07b7dd70-1310-4c37-b7df-4518dbf18363/dfbfntg-0f8a78e9-9c53-4fe5-8107-be41e150b8d1.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzA3YjdkZDcwLTEzMTAtNGMzNy1iN2RmLTQ1MThkYmYxODM2M1wvZGZiZm50Zy0wZjhhNzhlOS05YzUzLTRmZTUtODEwNy1iZTQxZTE1MGI4ZDEucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.gpps4YAuSbCBFdAtia-C_cUOYZebLGuWci4plsTodXI"
          alt="Talon Abraxas art"
        />
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/07b7dd70-1310-4c37-b7df-4518dbf18363/dcwa570-e4cc9612-4d98-4051-830d-032e9dfca2ac.png/v1/fill/w_600,h_1904,q_80,strp/grand_guignol_by_talonabraxas_dcwa570-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTkwNCIsInBhdGgiOiJcL2ZcLzA3YjdkZDcwLTEzMTAtNGMzNy1iN2RmLTQ1MThkYmYxODM2M1wvZGN3YTU3MC1lNGNjOTYxMi00ZDk4LTQwNTEtODMwZC0wMzJlOWRmY2EyYWMucG5nIiwid2lkdGgiOiI8PTYwMCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.uNlpO07ADuEkCaOZ8WGeiIl22tKaEUvH-naRJ-nIcas"
          alt="Talon Abraxas art"
        />
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/07b7dd70-1310-4c37-b7df-4518dbf18363/dcw3dj6-770cb9d2-8843-4128-bf0a-14088cdcb7c0.png/v1/fill/w_600,h_1800,q_80,strp/ariel_s_song_by_talonabraxas_dcw3dj6-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTgwMCIsInBhdGgiOiJcL2ZcLzA3YjdkZDcwLTEzMTAtNGMzNy1iN2RmLTQ1MThkYmYxODM2M1wvZGN3M2RqNi03NzBjYjlkMi04ODQzLTQxMjgtYmYwYS0xNDA4OGNkY2I3YzAucG5nIiwid2lkdGgiOiI8PTYwMCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.3y62hjpLzXXYgmAfd1YbyIkDcal1CxFW5A4VQxCDt6Y"
          alt="Talon Abraxas art"
        />
        <img
          src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/07b7dd70-1310-4c37-b7df-4518dbf18363/df99qzr-3e83c2b0-b5c9-4300-b5cf-bd9ede32453f.png/v1/fill/w_600,h_885,q_80,strp/ezekiel_s_wheel_by_talonabraxas_df99qzr-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9ODg1IiwicGF0aCI6IlwvZlwvMDdiN2RkNzAtMTMxMC00YzM3LWI3ZGYtNDUxOGRiZjE4MzYzXC9kZjk5cXpyLTNlODNjMmIwLWI1YzktNDMwMC1iNWNmLWJkOWVkZTMyNDUzZi5wbmciLCJ3aWR0aCI6Ijw9NjAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.PQ6qDzP1e_q3n2hry8P0M7vm9PvRjDYLFv7YpsB-WSA"
          alt="Talon Abraxas art"
        />
      </div>
    </Layout>
  );
};

export default Talon;
